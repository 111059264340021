import React from "react";
import { motion } from "framer-motion";
import { RouletteBet } from "./RouletteBet";

export const Roulette = ({room, duration, start, setStart, maxSum, bets,currentUsers, winner, showWinner}) => {
    const [users, setUsers] = React.useState([])
    const [go, setGo] = React.useState(false);
    const [x, setX] = React.useState(0);
    const [userIndex, setUserIndex] = React.useState();

    const myRef = React.useRef(null);
    let refs = React.useRef([]);
    React.useEffect(()=> {
        setGo(false)
        setX(0)
    }, [room])
    const run = (data, arr) => {
        try {
            let newArr = [];
            for (let i = 0; i < 20; i++) {
                newArr = [...arr, ...newArr];
            }
            const getIndex = newArr.findIndex((elem) => elem.color === data);
            setUsers(newArr);
            const getX =
                (myRef?.current.offsetWidth / 2) * 9 -
                myRef?.current.getBoundingClientRect().left +
                refs.current[getIndex].getBoundingClientRect().left +
                randWidth(refs.current[getIndex].offsetWidth);
            setUserIndex(getIndex);
            setX(getX);
            setGo(true);
        } catch (e) {

        }
    };

    const randWidth = (width) => {
        return Math.floor(Math.random() * width - 1) + 1;
    };


    const roulette = React.createRef()
    const [tses, setTses] = React.useState({})

    let getWidth = 0
    React.useEffect(()=> {
        setTses(roulette.current.offsetWidth)
        let usersArr = currentUsers
        usersArr.forEach((user) => {
            user.width = Test(user.sum, user.percent)
        })
        setUsers(usersArr)
    }, [bets])

    React.useEffect(()=>{
        if(start) {
            let usersArr = currentUsers
            usersArr.forEach((user) => {
                user.width = `${user.percent}%`
            })
            setUsers(usersArr)
            setTimeout(() => run(winner, currentUsers), 2000)
        }

    }, [start, bets])
    const tst = () => {
        let usersArr = users
        usersArr.forEach((user) => {
            user.width = `${user.percent}%`
        })
        setUsers(usersArr)
    }
    const Test = (width, percent) => {
        getWidth = +width
        let prc = roulette.current.offsetWidth
        let widthUser = getWidth / maxSum * 100
        let kf = prc / 100
        width = kf * widthUser
        return `${widthUser.toFixed(2)}%`
    }

    return (
        <div className={"roulette__container"} ref={roulette}>
            <div className="roulette__arrow"><span></span></div>
            {showWinner.winner && (
                <RouletteBet
                    style={{
                        display: "block",
                        position: "absolute",
                        zIndex: "2",
                        height: "100%"
                    }}
                    winner
                    endAnimation={{setGo, duration: duration.setDuration}}
                    color={users[userIndex]?.color}
                    width={users[userIndex]?.chance}
                />
            )}
            <motion.div
                ref={myRef}
                animate={
                    go
                        ? { transform: winner ? `translate3d(-${x}px, 0px, 0px)` : `translate3d(0px, 0px, 0px)` }
                        : { transform: `translate3d(0px, 0px, 0px)` }
                }
                initial={{transform: `translate3d(0px, 0px, 0px)` }}
                transition={{
                    duration: duration.duration,
                    type: "keyframes",
                    from: `translate3d(0px, 0px, 0px)`,
                    ease: [.02,.12,.01,.99]
                }}
                onAnimationComplete={(e) => {
                    if(go) {
                        if(winner) {
                            setTimeout(() => showWinner.set(true) , 1000)
                        }
                        setStart(false)
                    };
                }}
                className={`roulette`}
            >
                <div className={"roulette__users"}>
                    {users &&
                    users.map((user, index) => (
                        <RouletteBet
                            ref={(el) => refs.current[index] = el}
                            key={index}
                            start={start}
                            color={user.color}
                            width={user.width}
                        />
                    ))}
                </div>
            </motion.div>

        </div>

    );
}

