import React from "react";
import { motion } from "framer-motion";

export const RouletteBet = React.forwardRef((props, ref) => {
    const [w, setNewWidth] = React.useState({old:"0%", new: "0%"})
    React.useEffect(() => {
        setNewWidth(prevState => (
            {old: prevState.new, new: props.width}
        ))
    },[props.width])

    return (
        <motion.div
            ref={!props.winner ? (el) => ref(el, props.key) : ref}
            animate={
                props.winner
                    ? {
                        scaleX: [0, 1],

                    }
                    : {
                        minWidth: !props.start ? [w.old, w.new] : props.width
                    }
            }
            transition={{
                duration: 0.5,
                type: "keyframes"
            }}
            onAnimationComplete={() => {
                if(props.winner){
                    props.endAnimation.setGo(false)
                    props.endAnimation.duration(10)
                }
            }}
            className={"roulette__bet roulette__user"}
            style={{
                ...props.style,
                minWidth: props.winner ? "100%" : props.width,
                background: props.color
            }}
        >
        </motion.div>
    );
});
