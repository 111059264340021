import React from "react";
import { MDBIcon } from "mdbreact";
import { NavLink } from "react-router-dom";
import img from "./img.png";
import { toast } from "../components/Toast/toast";
import useLang from "../hooks/lang.hook";
import useAuth from "../hooks/AuthHook";
import { withdrawRef } from "../api";

export const ReferralPage = (props) => {
  const { strings } = useLang();
  const { user, setUser } = useAuth();


  const withdrawAction = async () => {
    withdrawRef().then(val => {
      if(val.success) {
        setUser(prev => ({...prev, referralBalance: val.data.referralBalance, balance: val.data.balance }))
      }
    })
  }
  const refInput = React.useRef(null);
  const copyLink = (e) => {
    refInput.current.select();
    document.execCommand("copy");
    toast({
      winner: false,
      success: true,
      title: "Успех!",
      message: "Ссылка скопирована.",
    });
  };

  return (
    <div className="referral-page w-100 align-self-start overflow-auto scrollbar scrollbar-primary d-flex flex-row flex-wrap mainColor mt-2">
      <NavLink
        className="color-text-lg w-100 backroom d-flex align-items-center m-3"
        to={"/"}
      >
        <MDBIcon icon="long-arrow-alt-left" /> <span>{strings.back}</span>
      </NavLink>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <strong className="textMain">
              {strings.referral.subtitle}
            </strong>
            <br />
            <h3 className="color__green">
              <strong>{strings.referral.title}</strong>
            </h3>
          </div>
          <div className="col-lg-12 mt-5">
            <div className="p-0 col-lg-6 d-flex flex-column">
              <span className="color-text-lg fs_14 font-weight-normal">
                {strings.referral.link}
              </span>
              <div className="d-flex justify-content-between flex-wrap align-items-center">
                <input
                  ref={refInput}
                  id="inviteLink"
                  value={`${window.location.host}/?ref=${user?._id ?? ''}`}
                  readOnly
                  placeholder="ссылка"
                  className="flex-1 payment__input mainPanelColor"
                />
                <button
                  onClick={() => copyLink(refInput)}
                  className="btn font-weight-bold button d-flex align-items-center"
                >
                  <MDBIcon
                    icon="clone"
                    style={{ fontSize: "20px", paddingRight: "10px" }}
                  />
                  <strong className="text-dark">
                    {strings.referral.copy}
                  </strong>
                </button>
              </div>
              <div className="d-flex align-items-center mt-5">
                <div
                  style={{ width: "55px" }}
                  className="connected d-flex justify-content-center rounded-circle p-3 mr-4"
                >
                  <MDBIcon
                    className="d-block"
                    style={{ fontSize: "25px" }}
                    icon="users"
                  />
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <h4 className="textMain">
                    <strong>{user?.referralCount ?? 0}</strong>
                  </h4>
                  <p className="color-text-lg m-0">
                    {strings.referral.users}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                <div
                  style={{ width: "55px" }}
                  className="connected d-flex justify-content-center rounded-circle p-3 mr-4"
                >
                  <MDBIcon
                    className="d-block"
                    style={{ fontSize: "25px" }}
                    icon="dollar-sign"
                  />
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <h4 className="textMain">
                    <strong>{user?.referralSum?.toFixed(2) ?? 0}</strong>
                  </h4>
                  <p className="color-text-lg m-0">
                    {strings.referral.balance}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                <div
                  style={{ width: "55px" }}
                  className="connected d-flex justify-content-center rounded-circle p-3 mr-4"
                >
                  <MDBIcon
                    className="d-block"
                    style={{ fontSize: "25px" }}
                    icon="percentage"
                  />
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <h4 className="textMain">
                    <strong>{user?.referralPercent ?? 0}%</strong>
                  </h4>
                  <p className="color-text-lg m-0">
                    {strings.referral.percent}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                <div
                  style={{ width: "55px" }}
                  className="connected d-flex justify-content-center rounded-circle p-3 mr-4"
                >
                  <MDBIcon
                    className="d-block"
                    style={{ fontSize: "25px" }}
                    icon="piggy-bank"
                  />
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <h4 className="textMain">
                    <strong>{user?.referralBalance?.toFixed(2) ?? 0}</strong>
                  </h4>
                  <p className="color-text-lg m-0">
                    {strings.referral.sum}
                  </p>
                </div>
                <div onClick={withdrawAction} className="p-2 ml-5 pb-2 pr-4 pl-4 font-weight-bold white-grad my-text-dark button m-1">
                  <span className="d-flex flex-center">{strings.referral.withdraw}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-12 d-flex flex-wrap justify-content-end align-items-center"
            style={{ marginTop: "500px" }}
          >
            <div className="referral_img">
              <img style={{ width: "100%" }} src={img} alt="" />
            </div>
            <div className="d-flex referral_text flex-column">
              <strong className="textMain">
                {strings.referral.bottomSubtitle}
              </strong>
              <br />
              <h3 className="color__green">
                <strong>{strings.referral.bottomTitle}</strong>
              </h3>
              <p
                className="color-text-lg"
                dangerouslySetInnerHTML={{
                  __html: strings.referral.description,
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
